@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.header .header__left,
.header .header__center,
.header .header__right {
  display: flex;
  align-items: center;
  height: 100%;
}
.header {
  position: fixed;
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #464749;
  box-shadow: none;
}
@media screen and (max-width: 500px) {
  .header {
    position: fixed;
    height: 35px;
  }
}
.header .header__left {
  border-right: 1px solid #fff;
  background-color: #000;
}
.header .header__left a {
  height: 100%;
}
@media screen and (max-width: 500px) {
  .header .header__left {
    width: 100px;
    border-right: none;
  }
}
.header .header__center {
  flex: 2;
}
.header .header__right {
  margin-left: auto;
}
.header .header__info {
  width: 100%;
}
.header .header__info .header__info__building-name {
  margin-left: 12px;
  padding-top: 2px;
  color: #fff;
  text-transform: uppercase;
}
.header .header__info .floor-level__label {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  margin-left: 24px;
  padding: 2px 8px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #000;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
}
.header .header__info .floor-level__label:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -9px;
  margin: 0;
  padding: 0;
  width: 0px;
  height: 0px;
  border-top: 9.5px solid transparent;
  border-right: 9.5px solid #000;
  border-bottom: 9.5px solid transparent;
  content: '';
}
.header__link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 65px;
  height: 100%;
  border-right: 1px solid #fff;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  font-size: 1.3rem;
  cursor: pointer;
}
.header__link:hover {
  background-color: #38393a;
}
.header__link.router-link-active {
  background-color: #fff;
  color: #000;
  font-weight: 500;
}
/*# sourceMappingURL=src/app/components/layout/header/simple-header.css.map */